<template>
  <div v-loading="formLoading">
    <div class="card mb-5 mb-xl-10" v-if="customerId">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldCustomerData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2">{{ oldCustomerData.email }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formRef"
          class="w-100 demo-ruleForm"
          status-icon
        >
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">Customer Name</label>
                <el-form-item prop="name">
                  <el-input size="large"
                    v-model="formData.name"
                    type="text"
                    placeholder="Customer Name"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">Customer Email</label>
                <el-form-item prop="email" abel="Email">
                  <el-input size="large"
                    v-model="formData.email"
                    type="text"
                    placeholder="Customer Email"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9" v-if="customerId == 0 ">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">Password</label>
                <el-form-item prop="password">
                  <el-input size="large"
                    v-model="formData.password"
                    type="password"
                    placeholder="Password"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">Confirm Password</label>
                <el-form-item prop="confirmPassword">
                  <el-input size="large"
                    v-model="formData.confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="fs-6 fw-bold mb-2">Super Admin</label><br>
                <el-switch v-model="formData.isAdmin" @change="updateRole($event)"/>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="fs-6 fw-bold mb-2 required">User Role</label>
                <el-form-item prop="roles">
                  <el-select
                    :disabled="roleDisable"
                    class="w-100"
                    v-model="formData.roles"
                    multiple
                    filterable
                    tag-type="success"
                    allow-create
                    default-first-option
                    placeholder="Select User Role"
                  >
                    <el-option
                      v-for="item in rolesData"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9"  v-if="(typeof $route.params.id !== 'undefined') && formData.isAdmin == 0">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class=" fs-6 fw-bold mb-2 ml-5">{{ "Password" }}</label>
                <el-radio-group class="ms-10" v-model="formData.isPasswordChange">
                  <el-radio :label="0">{{ "Leave Unchanged" }}</el-radio>
                  <el-radio :label="1">{{ "Set To" }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="row g-9" v-if="(typeof $route.params.id !== 'undefined') && formData.isPasswordChange == 1">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">
                  <span>{{formData.isPasswordChange == 1 ? "New Password" : "Password" }} </span>
                </label>
                <el-form-item prop="password">
                  <el-input
                    size="large"
                    v-model="formData.password"
                    type="password"
                    placeholder="Password"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">{{ "Confirm Password" }}</label>
                <el-form-item prop="confirmPassword">
                  <el-input
                    size="large"
                    v-model="formData.confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 mb-5 mt-12">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading"  @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{constants.general.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'customers'})">{{constants.general.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUpdated } from 'vue';
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { ElNotification } from 'element-plus';
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import useEventBus from '../../composable/useEventBus'
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "add-new-customer",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formRef = ref();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const loadingback = ref(false);
    const rolesData = ref([]);
    const roleDisable = ref(false);
    const customerId = ref(0);
    const formLoading = ref(false);
    const constants = globalConstant;
    const oldCustomerData = reactive({
      name: '',
      description: ''
    });
    const formData = reactive({
      name: "",
      email: "",
      password: '',
      confirmPassword: '',
      roles: [],
      initRoles: [],
      isAdmin: false,
      isPasswordChange: 0,
    });
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName=="save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push({ name: 'customers'});
      }
    })

    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })

    //Different validations
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else if (value.length <= 7) {
        callback(new Error("Please enter minimum 8 character password"));
      } else {
        if (formData.confirmPassword !== '') {
          if (!formRef.value) return
          formRef.value.validateField('confirmPassword', () => null)
        }
        callback()
      }
    }

    const validateRole = (rule, value, callback) => {
      if (!formData.isAdmin) {
        if (value.length == 0) {
          callback(new Error('Please select at least one role'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== formData.password) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    }

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Customer name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Customer email is required",
          trigger: "change",
        },
        {
          type: 'email',
          message: 'Please enter a valid email address.',
          trigger: ['blur', 'change'],
        },
      ],
      password: [{ validator: validatePassword, trigger: 'blur' }],
      confirmPassword: [{ validator: validateConfirmPassword, trigger: 'blur' }],
      roles: [
        {
          validator: validateRole,
          trigger: "change",
        },
      ],
    });

    const handleError = (err) => {
      let response = err.response;
      if (response) {
        let status = response.status;
        let message = response.data.message;
        if (status == 404) {
          router.push({ path: "/customers" });
        }
        if (typeof message == "object") {
          let msgString = '';
          message.forEach((obj) => {
            msgString += '<p>'+obj+'</p>';
          });
          ElNotification({dangerouslyUseHTMLString: true, title: 'Error', message: msgString, type: 'error'})
        } else {
          ElNotification({title: 'Error', message: message, type: 'error'})
        }
      }
      loading.value = false;
    }

    //Save the customer data
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction = false) => {
      formEl = (isToolbarButtonsSubmitAction)?formEl.value:formEl;
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          (isToolbarButtonsSubmitAction)?emitEvent('loader',{"save_loader":true,"action":btnName}):(btnName == "save")?(loading.value = true):(loadingback.value = true);
          let requestUrl = 'users';
          if (customerId.value > 0) {
            requestUrl = 'users/'+customerId.value;
            formData._method = "PUT";
            formData.id = customerId.value;
          }
          ApiService.post(requestUrl, formData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                oldCustomerData.name = formData.name;
                oldCustomerData.email = formData.email;
                if (customerId.value == 0) customerId.value = response.data.data.id;
                notificationFire(message, "success")
                if (btnName == 'save') {
                  (isToolbarButtonsSubmitAction)?emitEvent('loader',{"save_loader":false,"action":btnName}):(btnName == "save")?(loading.value = false):(loadingback.value = false);
                  if (typeof route.params.id === "undefined") {
                    setCurrentPageBreadcrumbs(formatText('edit-customer'), [{"name": "Customers", "path": "customers"}, {"name": formatText('edit-customer') , "active": true}]);
                    router.push('/customers/edit/'+customerId.value);
                  }
                } else if (btnName == 'save_back') {
                  router.push('/customers');
                }
              }
            }
          })
          .catch((error) => {
            (isToolbarButtonsSubmitAction)?emitEvent('loader',{"save_loader":false,"action":btnName}):(btnName == "save")?(loading.value = false):(loadingback.value = false);
            handleError(error)
          });
        }
      })
    }

     //Get all roles
    const getRoles = async () => {
      await ApiService.query("roles")
      .then(({ data }) => {
        if (data.data) {
          for (var k = 0; k < data.data.length; k++) {
            rolesData.value.push(data.data[k].name);
          }
        }
      })
      .catch((error) => {
        handleError(error)
      });
    }

    const updateRole = (status) => {
      if(status == true){
        formData.roles = [];
        roleDisable.value = true;
      }else{
        formData.roles =  formData.initRoles;
        roleDisable.value = false;
      }
    }
    //Get customer details
    const getCustomerDetails = async () => {
      formLoading.value = true;
      await ApiService.query("users/"+route.params.id)
      .then((response) => {
        let userData = response.data.data;
        formData.name = oldCustomerData.name = userData.name;
        formData.email = oldCustomerData.email = userData.email;
        if (userData.rolelist == "Super Admin") {
          formData.isAdmin = true;
          roleDisable.value = true;
        } else {
          formData.roles = userData.rolelist;
          formData.initRoles = userData.rolelist;
        }
        formLoading.value = false;
      })
      .catch((error) => {
        formLoading.value = false;
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          ElNotification({
            title: 'Error',
            message: message,
            type: 'error',
          })
          if (status == 404) {
            router.push('/customers');
          }
        }
        //Call common error function here
      });
    }
    
    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :constants.general.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader':false, 'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed)}, {"name": constants.general.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": constants.general.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }

    //Set breadcrumbs and get the orders
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Customers", "path": "customers"}, {"name": formatText(route.name) , "active": true}]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createCustomer") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateCustomer") || role == "Super Admin")
        permission.isEditAllowed = true;

      getRoles();
      if (route.params.id) {
        customerId.value = route.params.id
        await getCustomerDetails();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    return {
      formData,
      rules,
      formRef,
      loading,
      loadingback,
      rolesData,
      roleDisable,
      submitForm,
      customerId,
      formLoading,
      constants,
      permission,
      oldCustomerData,
      updateRole
    };
  }
}
</script>